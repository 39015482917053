
export default {
  layout: 'layout-full-page',
  mounted() {
    // check if on client not nuxt server 
    if (typeof window !== 'undefined') {
      this.$cookies.removeAll();
      localStorage.clear();
    }
    this.$fb.enable();

  },
  created() {
  // clear all cookies 

    this.$gtm.init('GTM-NK448G3');
  },
  methods: {
    signIn() {
      this.$auth.login();
    },
  },
};
